import { Route, Routes } from 'react-router-dom'

import ProtectedRoute from './components/protected.route'

import Layout from './components/Layout'

import Home from './pages/Home'
import Contato from './pages/Contato'
import Login from './pages/Login'

import Usuarios from './pages/Usuarios'
import Usuario from './pages/Usuario'


import UsuariosAnvisa from './pages/UsuariosAnvisa'
import UsuarioAnvisa from './pages/UsuarioAnvisa'

import GestoresMunicipais from './pages/GestoresMunicipais'
import GestorMunicipal from './pages/GestorMunicipal'

import Medicos from './pages/Medicos'
import Medico from './pages/Medico'


import Farmacia from './pages/Farmacia'
import Farmacias from './pages/Farmacias'

import NotificacaoRange from './pages/NotificacaoRange'


import AutoCadastro from './pages/AutoCadastro'
import AutoCadastroSucesso from './pages/AutoCadastroSucesso'

import ListaPublicacoes from './pages/ListaPublicacoes'
import Historico from './pages/Historico'

import Publicacao from './pages/Publicacao'
import TermoNotificacoes from './pages/TermoNotificacoes'
import TermoNotificacao from './pages/TermoNotificacao'

import EsqueciMinhaSenha from './pages/EsqueciMinhaSenha'
import SenhaEnviadoEmail from './pages/SenhaEnviadoEmail'

import GestoresRegionais from './pages/GestoresRegionais'
import GestorRegional from './pages/GestorRegional'
//import FaleConosco from './pages/FaleConosco'
import MensagensFaleConosco from './pages/MensagensFaleConosco'
import MensagemFaleConosco from './pages/MensagemFaleConosco'
import NovosCadastros from './pages/NovosCadastros'



//function Rotas() {
const Rotas = () => {

    return (

        <Routes>

            <Route path='/' element={<Login ><Layout /></Login>} /> 
            <Route path='/EsqueciMinhaSenha' element={<EsqueciMinhaSenha />} /> 
            <Route path='/EsqueciMinhaSenha/:usuario_id' element={<EsqueciMinhaSenha />} /> 
            <Route path='/EsqueciMinhaSenha/:usuarioAnvisa_id' element={<EsqueciMinhaSenha />} /> 
            <Route path='/senhaEnviadoEmail/:usuario_id' element={<SenhaEnviadoEmail />} /> 
            <Route path='/senhaEnviadoEmail/:usuarioAnvisa_id' element={<SenhaEnviadoEmail />} />



        
            <Route path='/AutoCadastro' element={<AutoCadastro/>}/>
            <Route path='/AutoCadastroSucesso' element={<AutoCadastroSucesso/>}/>
            <Route path='/AutoCadastroSucesso/:slug' element={<AutoCadastroSucesso/>}/>
            {/*<Route path='/FaleConosco' element={<FaleConosco/>}/> */}
            <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>

                <Route path='/Home' element={<Home/>}/>
                <Route path='/Contato' element={<Contato/>}/>

                <Route path='/Usuarios' element={<Usuarios/>}/>
                <Route path='/Usuario' element={<Usuario/>}/>
                <Route path='/Usuario/:usuario_id' element={<Usuario/>}/>
                <Route path='/Usuarios' element={<Usuarios/>}/>
                
                <Route path='/Usuario/:usuarioExtraiExcel' element={<Usuarios/>}/>
                <Route path='/Usuario/:usuarioAlteraSenha' element={<Usuario/>}/>

                <Route path='/GestoresMunicipais' element={<GestoresMunicipais/>}/>
                <Route path='/GestorMunicipal/:slug' element={<GestorMunicipal/>}/>
                
                <Route path='/UsuariosAnvisa' element={<UsuariosAnvisa/>}/>
                <Route path='/UsuarioAnvisa/:slug' element={<UsuarioAnvisa/>}/>
                <Route path='/UsuarioAnvisa' element={<UsuarioAnvisa/>}/>
                <Route path='/usuarioAnvisaAlteraSenha/:usuarioAnvisa_id' element={<UsuarioAnvisa/>}/>

                <Route path='/Medico' element={<Medico/>}/>
                <Route path='/Medico/:medico_id' element={<Medico/>}/>
                <Route path='/Medicos' element={<Medicos/>}/>
                <Route path='/Medico/:medicoExtraiExcel' element={<exportaExcel/>}/>
            
                <Route path='/Farmacia' element={<Farmacia/>}/>
                <Route path='/Farmacia/:farmacia_id' element={<Farmacia/>}/>
                <Route path='/Farmacias' element={<Farmacias/>}/>
                <Route path='/Farmacia/:farmaciaExtraiExcel' element={<exportaExcel/>}/>

                <Route path='/notificacaoRange' element={<NotificacaoRange/>}/>

               
                <Route path='/ListaPublicacoes' element={<ListaPublicacoes/>}/>
                <Route path='/Historico' element={<Historico/>}/>
                <Route path='/Publicacao/:publicacao_id' element={<Publicacao/>}/>
                {/*<Route path='/DeletaRegistro/:publicacao_id' element={<Publicacao/>}/>*/}
                <Route path='/ExtraiExcelPublicacao/:publicacao_id' element={<Publicacao/>}/>


                <Route path='/TermoNotificacoes' element={<TermoNotificacoes/>}/> 
                <Route path='/TermoNotificacoes/:termoNotificacao_id' element={<TermoNotificacoes/>}/> 


                <Route path='/TermoNotificacao' element={<TermoNotificacao/>}/> 
                <Route path='/TermoNotificacaoPdf/:termoNotificacao_id' element={<TermoNotificacao/>}/> 

                <Route path='/GestoresRegionais' element={<GestoresRegionais/>}/> 
                <Route path='/GestorRegional/:slug' element={<GestorRegional/>}/> 


                <Route path='/MensagensFaleConosco' element={<MensagensFaleConosco/>}/> 
                <Route path='/MensagemFaleConosco/:faleConosco_id' element={<MensagemFaleConosco/>}/> 

                <Route path='/NovosCadastros' element={<NovosCadastros/>}/> 

                
               
               

    
            </Route>


        </Routes>

    )

}


export default Rotas