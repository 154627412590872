import { useEffect, useState, useMemo } from 'react';
import api from "../components/api";
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const Historico = () => {

  const [rowSelection, setRowSelection] = useState({})

  const navigate = useNavigate();

  /*
  const [usuarioAnvisa_id, setUsuarioAnvisa_id] = useState('');
  const [status, setStatus] = useState('');
  const [dataHora, setDataHora] = useState('');
  const [publicado, setPublicado] = useState('');
  const [publicacao_id, setPublicacao_id] = useState('');
  const [totalHistorico, setTotalHistorico] = useState('');
  const [visa, setVisa] = useState('');
  const [gvs_descricao, setGvsDescricao] = useState('');
  const [numeroPublicacao, setNumeroPublicacao] = useState('');
  const [enviado, setEnviado] = useState('');
  

  */
  const [resultado, setResultado] = useState([]);
  const [historico_id, setHistorico_id] = useState('');
 /* const [status, setStatus] = useState('')
  const [status_gvs, setStatus_gvs] = useState('')*/
  const [totalNaoPublicadosAnvisa, setTotalNaoPublicadosAnvisa] = useState('');

  
  // Definição das colunas na tabela
  const columns = useMemo(() => [
    {
      accessorKey: 'nome',
      header: 'Usuário',
    },
    {
      accessorKey: 'status',
      header: 'Aprovação 1º nível',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
          align: 'center',
      },
      Cell: ({ renderedCellValue, row }) => (
      
          <span className={`badge badge-${ renderedCellValue == 'Ativo' ? 'success' : renderedCellValue == 'Inativo' ? 'danger' : 'primary' }`}>{renderedCellValue}</span>
    
      ),
    },

    {
      accessorKey: 'status_gvs',
      header: 'Aprovação 2º nível',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
          align: 'center',
      },
      Cell: ({ renderedCellValue, row }) => (
      
          <span className={`badge badge-${ renderedCellValue == 'Ativo' ? 'success' : renderedCellValue == 'Inativo' ? 'danger' : 'primary' }`}>{renderedCellValue}</span>
    
      ),
    },

    {
      accessorKey: 'visa',
      header: 'VISA',
    },
    {
      accessorKey: 'gvs_descricao',
      header: 'GVS',
    },
    {
      accessorKey: 'IBGE_CIDADE',
      header: 'Município',
    },


  ], []);


  
  const listaNaoPublicados = () => {
    //if (status === 'A' && status_gvs === 'A')
    api.get(`naoPublicados`).then((result) => {
      
      const registros = result.data;
      setTotalNaoPublicadosAnvisa(registros.length);
      setResultado(registros);
  })
  .catch((err) => {
      console.log(err.response);
  });
};

      






  useEffect(() => {
  
    listaNaoPublicados();
  }, []);
  


  const situacaoDeleta = () => {

    const keys = Object.keys(rowSelection);
    const numericKeys = keys.map(Number);

    //console.log(numericKeys)
    //return false
    
   
    if (keys.length > 0) {
      window.$.confirm({
        title: 'Confirmação',
        content: `Confirma deletar ${numericKeys.length} registro(s)?`,
        buttons: {
          yes: {
            text: 'Sim',
            btnClass: 'btn-green',
            keys: ['enter', 'shift'],
            action: function () {

              var dataPost = {

                keys: numericKeys,

              }
              
              api.post(`deletaRegistroHistorico`, dataPost).then((result) => {
                  console.log(result.data);
                  listaNaoPublicados()
                  toast.success('Registro descartado com sucesso!', { theme: 'colored' });
                })
                .catch((err) => {
                  console.error('Erro ao deletar registro:', err);
                  toast.error('Erro ao descartar registro!', { theme: 'colored' });
                });
            },
          },
          cancel: {
            text: 'Não',
            btnClass: 'btn-red',
            keys: ['esc'],
            action: function () {
              console.log('Ação cancelada pelo usuário.');
            },
          },
        },
      });
    }
  };
  
  
  


  const publicaSelecionados = () => {


    

    //console.log(rowSelection)
    //return false

    const keys = Object.keys(rowSelection);
    const numericKeys = keys.map(Number);

    var dataPost = {

      historico_id_array: numericKeys,

    };

    if (numericKeys.length == 0) {

      toast.error('Selecione ao menos um registro para publicar.', { theme: "colored" });
      return;

    } else {

      window.$.confirm({
        title: 'Confirmação',
        content: `Confirma publicar ${numericKeys.length} registro(s)?`,
        buttons: {
          yes: {
            text: 'Sim',
            btnClass: 'btn-green',
            keys: ['enter', 'shift'],
            action: function () {

              api.post(`publicacao`, dataPost).then((result) => {

                console.log(result.data);

                var data = result.data.status;
                var publicacao_id = result.data.publicacao_id;

                if (data === 'ok') {

                  console.log('salvo com sucesso');
                  toast.success('Publicação enviada com sucesso!', { theme: "colored" });
                  navigate(`/publicacao/${publicacao_id}`);

                } else {
                  toast.error(data, { theme: 'colored' });
                }
              }).catch((err) => {
                toast.error('Erro ao salvar o usuário.' + err.message, { theme: "colored" });
                console.log(err.response);
              });



            }
          },
          cancel: {
            text: 'Não',
            btnClass: 'btn-red',
            keys: ['enter', 'shift'],
            action: function () {

              // inserir ação para nao
              //console.log('cancelado pelo usuário')

            },
          },

        }
      });



    }

  }




  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div data-collapsed="0" className="card">
            <div className="card-header">
              <div className="card-title">
                <h2 className="card-title">Registros a serem publicados</h2>
                <br />
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-info mt-2 custom-button"
                  onClick={() => publicaSelecionados()}
                >
                  Publicar selecionado(s)
                </button>
                &nbsp;
                &nbsp;
                <button
                  type="button"
                  className="btn btn-danger mt-2 custom-button"
                  onClick={() => situacaoDeleta()}
                >
                  Deletar selecionado(s)
                </button>
                &nbsp;
                &nbsp;
                <button
                  type="button"
                  className="btn btn-warning mt-2 custom-button"
                  onClick={() => navigate('/ListaPublicacoes')}
                >
                  Voltar
                </button>
               
              </div>
              
              

              <br /><br />
              <div className="row">
                <MaterialReactTable
                  columns={columns}
                  data={resultado}
                  localization={MRT_Localization_PT_BR}
                  initialState={{ density: 'compact',  sorting: [{ id: 'nome', desc: false }], }}
                  enableRowSelection
                  getRowId={(row) => row.historico_id} 
                  onRowSelectionChange={setRowSelection} 
                  state={{ rowSelection }}
                  muiTableHeadCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    //onClick: () => navigate(`/DetalhesHistorico/${row.original.historico_id}`),
                    sx: {
                      //cursor: 'pointer',
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};



export default Historico;
