import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const Publicacao = () => {

    const [rowSelection, setRowSelection] = useState({})

    const navigate = useNavigate();
    //const [status, setStatus] = useState();

    const columns = useMemo(() => [

        {
            accessorKey: 'nome',
            header: 'Nome'
        },

        {
            accessorKey: 'visa',
            header: 'Visa',
            muiTableHeadCellProps: {
                align: 'center',
                sx: { fontSize: '0.875rem' },
            },
            muiTableBodyCellProps: {
                align: 'center',
                sx: { fontSize: '0.875rem', padding: '0.25rem' },
            },
            Cell: ({ renderedCellValue, row }) => (
                

                <span className={`badge badge-${ renderedCellValue == 'CVS' ? 'primary' : renderedCellValue == 'GVS' ? 'info' : renderedCellValue == 'Município' || renderedCellValue == 'Municipio' ? 'warning' : '' }`}>{renderedCellValue}</span>

            ),

        },

        {
            accessorKey: 'gvs_descricao',
            header: 'GVS',
        },

        {
            accessorKey: 'ibge_cidade',
            header: 'Municipío',
        },

        {
            accessorKey: 'status',
            header: 'Status Atual',
            muiTableHeadCellProps: {
                align: 'center',
                sx: { fontSize: '0.875rem' },
            },
            muiTableBodyCellProps: {
                align: 'center',
                sx: { fontSize: '0.875rem', padding: '0.25rem' },
            },
            Cell: ({ renderedCellValue }) => (
                <span
                    className={`badge badge-${renderedCellValue === 'Ativo'
                            ? 'success'
                            : renderedCellValue === 'Inativo'
                                ? 'danger'
                                : 'primary'
                        }`}
                    style={{ fontSize: '0.875rem' }}
                >
                    {renderedCellValue}
                </span>
            ),
        },

        {
            accessorKey: 'status',
            header: 'Ação',
            Cell: ({ row }) => {
                const historico_id = row.original.historico_id;

                //setStatus(row.original.status)

                const handleStatusToggle = async () => {
                    try {

                        var dataPost = {

                            status: row.original.status == 'Ativo' ? 'I' : row.original.status == 'Inativo' ? 'A' : null
                        }

                        //console.log(`atualizaStatus/${historico_id}`, 'aqui')
                        //return false
                        const result = await api.put(`atualizaStatus/${historico_id}`, dataPost);

                        if (result.data.status === 'ok') {
                            //const newStatus = status === 'I' ? 'A' : 'I';
                            //setStatus(newStatus);
                            carregaPublicacao()
                            toast.success('Registro salvo com sucesso!', { theme: "colored" });
                        }
                    } catch (err) {
                        console.error('Erro ao atualizar status:', err);
                        toast.error('Erro ao atualizar status!', { theme: "colored" });
                    }
                };

                const confirmaMudancaStatus = () => {
                    //const acao = status === 'I' ? 'ativação' : 'inativação';

                    window.$.confirm({
                        title: 'Confirmação',
                        content: `Confirma ${row.original.status == 'Ativo' ? 'Inativar' : 'Ativar'}?`,
                        buttons: {
                            yes: {
                                text: 'Sim',
                                btnClass: 'btn-green',
                                keys: ['enter'],
                                action: handleStatusToggle
                            },
                            cancel: {
                                text: 'Não',
                                btnClass: 'btn-red',
                                keys: ['esc'],
                                action: () => console.log('Operação cancelada pelo usuário')
                            }
                        }
                    });
                };

                return (
                    <button
                        onClick={confirmaMudancaStatus}
                        className="btn btn-outline-secondary btn-sm"
                    >
                        {row.original.status === 'Inativo' ? 'Ativar' : 'Inativar'}
                    </button>
                );
            }
        }
    ], []);






    const { publicacao_id } = useParams()

    const [numeroPublicacao, setNumeroPublicacao] = useState('')
    const [listaUsuariosPublicacao, setListaUsuariosPublicacao] = useState([])
    const [dataUsuariosPublicacao, setDataUsuariosPublicacao] = useState('')
    const [ad_new, setAd_new] = useState('')
    const [horaData, setHoraData] = useState('')
    const [dataPublicacao, setDataPublicacao] = useState('')

    const carregaPublicacao = () => {


        api.get(`publicacao/${publicacao_id}`).then((result) => {

            const dataPublicacao = result.data[0][0]
            const resultado = result.data[1]

            setDataUsuariosPublicacao(resultado)


            setAd_new(dataPublicacao.ad_new)
            setNumeroPublicacao(dataPublicacao.numeroPublicacao)
            setListaUsuariosPublicacao(dataUsuariosPublicacao)
            setDataPublicacao(dataPublicacao)


        }).catch((err) => {

            console.log(err.response)

        })


    }


    const pdfPublicacao = () => {
        api.get(`ExtraiPdf/${publicacao_id}`).then((result) => {
            console.log('We are here and the file is: ' + result.data.arquivo);

            const arquivoUrl = `https://apisecnr.saude.sp.gov.br/public/${result.data.arquivo}`;
            // Attempt to open the PDF in a new tab
            const newWindow = window.open(arquivoUrl, '_blank');

            if (newWindow) {
                newWindow.focus(); // Focus on the new window if successfully opened
            } else {
                alert('Failed to open PDF. Please allow pop-ups for this site.');
            }

        }).catch((err) => {
            console.error('Error retrieving the PDF:', err.response);
            alert('An error occurred while fetching the PDF. Please try again later.');
        });
    };




    const deletaUsuario = () => {

        const keys = Object.keys(rowSelection);
        const numericKeys = keys.map(Number);

        //console.log(numericKeys)
        //return false

        if (keys.length > 0) {

            window.$.confirm({
                title: 'Confirmação',
                content: `Confirma apagar ${numericKeys.length} registro(s)?`,
                buttons: {
                    yes: {
                        text: 'Sim',
                        btnClass: 'btn-green',
                        keys: ['enter', 'shift'],
                        action: function () {


                            var dataPost = {

                                keys: numericKeys,

                            }


                            api.post(`deletaRegistroPublicacao/${publicacao_id}`, dataPost).then((result) => {
                                console.log(result.data)
                                carregaPublicacao()
                                //console.log(result.data + 'aqui');
                                toast.success('Registro descartado com sucesso!', { theme: "colored" });

                            })
                                .catch((err) => {
                                    console.error('Erro ao deletar registro:', err);
                                    //console.log('aqui')
                                    toast.error('Erro ao descartar registro!', { theme: "colored" });
                                });
                        },
                    },
                    cancel: {
                        text: 'Não',
                        btnClass: 'btn-red',
                        keys: ['esc'],
                        action: function () {
                            console.log('Ação cancelada pelo usuário.');
                        },
                    },
                },
            });
        }
    };

    /* const alteraStatus = () => {
 
 
         api.put(`atualizaStatus/${publicacao_id}`).then((result) => {
 
 
          if (result.data.status == 'ok') {
 
                                 toast.success('Registro salvo com sucesso!', { theme: "colored" });
 
                              }
 
                         }).catch((err) => {
 
                             console.log(err.response)
 
                         })
 
     };
 */




    useEffect(() => {

        if (publicacao_id) {

            carregaPublicacao()


        }

    }, [publicacao_id])



    return (

        <div>

            <div class="row">
                <div class="col-lg-12">

                    <div data-collapsed="0" class="card">

                        <div class="card-header">
                            <div class="card-title">

                                <h2 class="card-title">Ofício</h2>

                            </div>
                        </div>



                        <div class="card-body">

                            <div class="row">

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-form-label">Número:</label>
                                        <input type="text" class="form-control" value={numeroPublicacao} disabled={true} />
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-form-label">Data/Hora:</label>
                                        <input type="text" class="form-control" value={ad_new} disabled={true} />
                                    </div>
                                </div>

                            </div>


                            <div className="row" style={{ marginTop: 15 }}>

                                <div class="col-lg-12">



                                    <MaterialReactTable

                                        columns={columns}
                                        data={dataUsuariosPublicacao}
                                        localization={MRT_Localization_PT_BR}
                                        initialState={{ density: 'compact' }}
                                        enableRowSelection
                                        getRowId={(row) => row.historico_id}
                                        onRowSelectionChange={setRowSelection}
                                        state={{ rowSelection }}
                                        muiTableHeadCellProps={{
                                            sx: {
                                                fontSize: {
                                                    xs: '8px',
                                                    sm: '9px',
                                                    md: '10px',
                                                    lg: '11px',
                                                    xl: '12px',
                                                },
                                            },
                                        }}
                                        muiTableBodyCellProps={{
                                            sx: {
                                                fontSize: {
                                                    xs: '8px',
                                                    sm: '9px',
                                                    md: '10px',
                                                    lg: '11px',
                                                    xl: '12px',
                                                },
                                            },
                                        }}
                                        muiTableBodyRowProps={({ row }) => ({
                                            //onClick: () => navigate(`/Usuario/${row.original.usuario_id}`),
                                            sx: {
                                                //  cursor: 'pointer',
                                            },
                                        })}
                                    />


                                    <div class="card-body">

                                        <button
                                            type="button"
                                            className="btn btn-warning mt-2"
                                            onClick={() => navigate('/ListaPublicacoes')}
                                        >
                                            Voltar
                                        </button>


                                        &nbsp;&nbsp;

                                        <button
                                            type="button"
                                            className="btn btn-info mt-2"
                                            onClick={() => pdfPublicacao()}
                                        >
                                            PDF
                                        </button>

                                        &nbsp;&nbsp;
                                        <button
                                            type="button"
                                            className="btn btn-danger mt-2 custom-button"
                                            onClick={() => deletaUsuario()}
                                        >
                                            Deletar selecionado(s)
                                        </button>







                                    </div>

                                </div>




                            </div>




                        </div>

                    </div>
                </div>

            </div>



        </div>

    )

}


export default Publicacao